import { render, staticRenderFns } from "./ImportScopes.vue?vue&type=template&id=3d6831ed&scoped=true&"
import script from "./ImportScopes.vue?vue&type=script&lang=js&"
export * from "./ImportScopes.vue?vue&type=script&lang=js&"
import style0 from "./ImportScopes.vue?vue&type=style&index=0&id=3d6831ed&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d6831ed",
  null
  
)

export default component.exports