<template>
    <div>
        <v-row v-if="$vuetify.breakpoint.smAndDown">
            <v-col cols="12">
                <span class="mr-3">Status:</span>
                <v-btn
                    v-if="quoteData"
                    :style="{ width: '100px' }"
                    rounded
                    small
                    elevation="0"
                    :color="getColor(quoteData.status)"
                    class="text-center btnStatus white--text"
                >
                    {{ showStatus(quoteData.status) }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutter>
            <v-col cols="12" xl="6" lg="6" md="6">
                <!-- Company details -->
                <v-row class="mb-3" no-gutters>
                    <v-col
                        cols="4"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex align-center"
                    >
                        <span>ID</span>
                    </v-col>
                    <v-col
                        cols="6"
                        xl="1"
                        lg="1"
                        md="1"
                        sm="1"
                        class="d-flex align-center"
                    >
                        <span v-if="quoteData">{{
                            quoteData.number
                                ? quoteData.number
                                : quoteData.shortId
                        }}</span>
                    </v-col>
                </v-row>
                <v-row class="" no-gutters>
                    <v-col
                        cols="4"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex align-center"
                    >
                        <span
                            >Account <br />
                            Manager</span
                        >
                    </v-col>
                    <v-col class="d-flex  flex-wrap">
                        <template>
                            <v-card
                                v-if="quoteData"
                                :disabled="
                                    quoteData.status != 'OPEN' &&
                                        quoteData.status != 'APPROVAL'
                                "
                                flat
                            >
                                <div
                                    v-if="quoteData.mainContact"
                                    class="d-flex my-1"
                                    :style="{ cursor: 'pointer' }"
                                    @click="openChangeMainContact"
                                >
                                    <v-avatar
                                        v-if="quoteData.mainContact"
                                        size="34"
                                        color="grey lighten-4"
                                        class="mr-1 ml-1"
                                    >
                                        <v-img
                                            v-if="
                                                quoteData.mainContact
                                                    .profilePicURL
                                            "
                                            :src="
                                                quoteData.mainContact
                                                    .profilePicURL
                                            "
                                        />
                                        <span v-else class="text-h6">
                                            {{
                                                quoteData.mainContact.name
                                                    .split(' ')
                                                    .map((n, i, a) =>
                                                        i === 0 ||
                                                        i + 1 === a.length
                                                            ? n[0]
                                                            : null
                                                    )
                                                    .join('')
                                                    .toUpperCase()
                                            }}
                                        </span>
                                    </v-avatar>
                                    <div
                                        class="d-flex flex-column justify-space-around me-4"
                                    >
                                        <span
                                            v-if="quoteData"
                                            class="font-weight-bold text-singleline"
                                        >
                                            {{ quoteData.mainContact.name }}
                                        </span>
                                        <span
                                            v-if="quoteData"
                                            class="text-caption text-singleline"
                                        >
                                            {{ quoteData.mainContact.role }}
                                        </span>
                                    </div>
                                </div>
                            </v-card>
                        </template>
                    </v-col>
                    <!--Change Main Contact-->
                    <v-dialog
                        v-if="
                            quoteData.status === 'OPEN' ||
                                quoteData.status === 'APPROVAL'
                        "
                        :retain-focus="false"
                        v-model="changeMainContact"
                        persistent
                        max-width="400px"
                    >
                        <ChangeMainContact
                            v-if="quoteData && changeMainContact"
                            @closeChangeMainContact="closeChangeMainContact"
                            @replaceMainContact="replaceMainContact"
                            :quoteId="quoteData.id"
                            :quote="quoteData"
                        />
                    </v-dialog>
                </v-row>
                <v-row no-gutters>
                    <v-col
                        cols="4"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex align-center"
                    >
                        <span
                            >Precon <br />
                            Manager</span
                        >
                    </v-col>
                    <v-col class="d-flex  flex-wrap">
                        <template>
                            <v-card
                                v-if="quoteData"
                                :disabled="
                                    quoteData.status != 'OPEN' &&
                                        quoteData.status != 'APPROVAL'
                                "
                                flat
                            >
                                <div
                                    v-if="quoteData.preconManagerInfo"
                                    class="d-flex my-1"
                                    :style="{ cursor: 'pointer' }"
                                    @click="openChangePreconManager"
                                >
                                    <v-avatar
                                        size="34"
                                        color="grey lighten-4"
                                        class="mr-1 ml-1"
                                    >
                                        <v-img
                                            v-if="
                                                quoteData.preconManagerInfo
                                                    .profilePicURL
                                            "
                                            :src="
                                                quoteData.preconManagerInfo
                                                    .profilePicURL
                                            "
                                        />
                                        <span v-else class="text-h6">
                                            {{
                                                quoteData.preconManagerInfo.name
                                                    .split(' ')
                                                    .map((n, i, a) =>
                                                        i === 0 ||
                                                        i + 1 === a.length
                                                            ? n[0]
                                                            : null
                                                    )
                                                    .join('')
                                                    .toUpperCase()
                                            }}
                                        </span>
                                    </v-avatar>
                                    <div
                                        class="d-flex flex-column justify-space-around me-4"
                                    >
                                        <span
                                            class="font-weight-bold text-singleline"
                                        >
                                            {{
                                                quoteData.preconManagerInfo.name
                                            }}
                                        </span>
                                        <span
                                            v-if="quoteData"
                                            class="text-caption text-singleline"
                                        >
                                            {{
                                                quoteData.preconManagerInfo.role
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </v-card>
                            <v-btn
                                v-if="!quoteData.preconManagerId && !history"
                                @click="changePreconManager = true"
                                class="my-2 ml-2"
                                depressed
                                fab
                                :disabled="
                                    quoteData.status != 'OPEN' &&
                                        quoteData.status != 'APPROVAL'
                                "
                                x-small
                                color="primary"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                    </v-col>
                    <!--Change Precon Manager-->
                    <v-dialog
                        :retain-focus="false"
                        v-model="changePreconManager"
                        persistent
                        max-width="400px"
                    >
                        <ChangePreconManager
                            v-if="quoteData && changePreconManager"
                            :quoteId="quoteData.id"
                            :quote="quoteData"
                            @closeChangePreconManager="closeChangePreconManager"
                            @replacePreconManager="replacePreconManager"
                        />
                    </v-dialog>
                </v-row>
                <v-row class="" no-gutters>
                    <v-col
                        cols="4"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex align-center"
                    >
                        <span>Estimator</span>
                    </v-col>
                    <v-col class="d-flex  flex-wrap">
                        <template>
                            <v-card
                                v-if="quoteData"
                                :disabled="
                                    quoteData.status != 'OPEN' &&
                                        quoteData.status != 'APPROVAL'
                                "
                                flat
                            >
                                <div
                                    v-if="quoteData.estimatorInfo"
                                    class="d-flex my-1"
                                    :style="{ cursor: 'pointer' }"
                                    @click="openChangeEstimator"
                                >
                                    <v-avatar
                                        size="34"
                                        color="grey lighten-4"
                                        class="mr-1 ml-1"
                                    >
                                        <v-img
                                            v-if="
                                                quoteData.estimatorInfo
                                                    .profilePicURL
                                            "
                                            :src="
                                                quoteData.estimatorInfo
                                                    .profilePicURL
                                            "
                                        />
                                        <span v-else class="text-h6">
                                            {{
                                                quoteData.estimatorInfo.name
                                                    .split(' ')
                                                    .map((n, i, a) =>
                                                        i === 0 ||
                                                        i + 1 === a.length
                                                            ? n[0]
                                                            : null
                                                    )
                                                    .join('')
                                                    .toUpperCase()
                                            }}
                                        </span>
                                    </v-avatar>
                                    <div
                                        class="d-flex flex-column justify-space-around me-4"
                                    >
                                        <span
                                            class="font-weight-bold text-singleline"
                                        >
                                            {{ quoteData.estimatorInfo.name }}
                                        </span>
                                        <span
                                            v-if="quoteData"
                                            class="text-caption text-singleline"
                                        >
                                            {{ quoteData.estimatorInfo.role }}
                                        </span>
                                    </div>
                                </div>
                            </v-card>
                            <v-btn
                                v-if="!quoteData.estimatorId && !history"
                                @click="changeEstimator = true"
                                class="my-2 ml-2"
                                depressed
                                fab
                                x-small
                                color="primary"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                    </v-col>
                    <!--Chnage Estimator-->
                    <v-dialog
                        v-if="
                            quoteData.status === 'OPEN' ||
                                quoteData.status === 'APPROVAL'
                        "
                        :retain-focus="false"
                        v-model="changeEstimator"
                        persistent
                        max-width="400px"
                    >
                        <ChangeEstimator
                            v-if="quoteData && changeEstimator"
                            :quoteId="quoteData.id"
                            :quote="quoteData"
                            @replaceEstimator="replaceEstimator"
                            @closeChangeEstimator="closeChangeEstimator"
                        />
                    </v-dialog>
                </v-row>
                <v-row no-gutters>
                    <v-col
                        cols="4"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex align-center"
                    >
                        <span>Collaborators</span>
                    </v-col>
                    <v-col class="d-flex align-center flex-wrap">
                        <v-card
                            v-for="(collaborator, index) in filterDataColab"
                            :key="index"
                            flat
                            class="mr-1 ml-1"
                        >
                            <div class="d-flex my-1">
                                <v-avatar
                                    color="grey lighten-4"
                                    size="34"
                                    class="mr-1"
                                >
                                    <v-img
                                        v-if="collaborator.profilePicURL"
                                        :src="collaborator.profilePicURL"
                                    />
                                    <span v-else class="text-h6">
                                        {{
                                            collaborator.name
                                                .split(' ')
                                                .map((n, i, a) =>
                                                    i === 0 ||
                                                    i + 1 === a.length
                                                        ? n[0]
                                                        : null
                                                )
                                                .join('')
                                                .toUpperCase()
                                        }}
                                    </span>
                                </v-avatar>
                                <div
                                    class="d-flex flex-column justify-space-around me-4"
                                >
                                    <span
                                        v-if="quoteData"
                                        class="font-weight-bold text-singleline"
                                    >
                                        {{ collaborator.name }}
                                    </span>
                                    <span
                                        v-if="quoteData"
                                        class="text-caption text-singleline"
                                    >
                                        {{ collaborator.role }}
                                    </span>
                                </div>
                            </div>
                        </v-card>
                        <v-btn
                            v-if="
                                quoteData &&
                                    (quoteData.status === 'OPEN' ||
                                        quoteData.status === 'APPROVAL') &&
                                    !history
                            "
                            @click="openDialogCollaborator"
                            class="my-2 ml-2"
                            depressed
                            fab
                            x-small
                            color="primary"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                    <v-dialog
                        :retain-focus="false"
                        v-model="addDialog"
                        persistent
                        max-width="400px"
                    >
                        <v-card>
                            <v-card-title class="text-h5"
                                >Add Collaborator</v-card-title
                            >
                            <v-card-text>
                                <v-form ref="collaborator">
                                    <v-autocomplete
                                        v-if="
                                            quoteData && quoteData.mainContact
                                        "
                                        @change="deleteSearch"
                                        :search-input.sync="search"
                                        label="Collaborator"
                                        v-model="quoteData.collaborators"
                                        :items="
                                            collaborators.filter(
                                                c =>
                                                    c.id !=
                                                    quoteData.mainContact.id
                                            )
                                        "
                                        item-text="name"
                                        chips
                                        item-value="id"
                                        multiple
                                    >
                                    </v-autocomplete>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    text
                                    color="secondary"
                                    @click="closeDialog"
                                >
                                    Close
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="addCollaborator"
                                    text
                                    color="error"
                                    :loading="loading"
                                >
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
                <v-row class="mb-4" no-gutters>
                    <v-col
                        cols="4"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex align-center"
                    >
                        <span>Bid Documents</span>
                    </v-col>
                    <v-col
                        v-if="quoteData"
                        class="d-flex align-center flex-wrap"
                    >
                        <v-btn
                            color="grey lighten-3"
                            elevation="0"
                            x-small
                            class="ma-1 text-capitalize grey--text text--darken-1  "
                            v-for="(file, i) in quoteData.docs"
                            :key="i"
                        >
                            <span @click="openFile(file.file)">{{
                                file.name
                            }}</span>
                            <v-icon
                                v-if="
                                    quoteData &&
                                        (quoteData.status === 'OPEN' ||
                                            quoteData.status === 'APPROVAL')
                                "
                                @click="deleteDocQuote(file.file)"
                                class="ml-1"
                                small
                            >
                                mdi-close-circle
                            </v-icon>
                        </v-btn>
                        <v-btn
                            @click="openQuoteDocument"
                            depressed
                            fab
                            x-small
                            color="primary"
                            class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                            v-if="
                                quoteData &&
                                    (quoteData.status === 'OPEN' ||
                                        quoteData.status === 'APPROVAL') &&
                                    !history
                            "
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-dialog
                    :retain-focus="false"
                    v-model="deleteDialog"
                    persistent
                    max-width="400px"
                >
                    <v-card>
                        <v-card-title class="text-h5"
                            >Delete Document</v-card-title
                        >
                        <v-card-text>
                            Are you sure you want to delete this document?
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                text
                                color="secondary"
                                @click="deleteDialog = false"
                            >
                                Close
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="deleteDoc" text color="error">
                                Delete
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <DocumentForm
                    v-if="quoteData"
                    v-model="dialogDocument"
                    :title="title"
                    :create-form="true"
                    @closeDialog="closeDialogDocument"
                    formUser=""
                    :quoteId="quoteData.id"
                    :quoteFiles="quoteData.files"
                    @addFile="addFile"
                />
                <v-row class="mb-4" no-gutters>
                    <v-col
                        cols="3"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex align-center"
                    >
                        <span>Attached</span>
                    </v-col>
                    <v-col
                        v-if="quoteData"
                        class="d-flex align-center flex-wrap"
                    >
                        <v-btn
                            color="grey lighten-3"
                            elevation="0"
                            x-small
                            class="ma-1 text-capitalize grey--text text--darken-1"
                            v-for="(file, i) in quoteData.attached"
                            :key="i"
                        >
                            <span @click="openFile(file.file)">{{
                                file.name
                            }}</span>
                            <v-icon
                                v-if="
                                    quoteData &&
                                        (quoteData.status === 'OPEN' ||
                                            quoteData.status === 'APPROVAL')
                                "
                                @click="deleteDocQuote(file.file)"
                                class="ml-1"
                                small
                            >
                                mdi-close-circle
                            </v-icon>
                        </v-btn>
                        <v-btn
                            v-if="
                                quoteData &&
                                    (quoteData.status === 'OPEN' ||
                                        quoteData.status === 'APPROVAL') &&
                                    !history
                            "
                            @click="openAttachDocument"
                            depressed
                            fab
                            x-small
                            class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                            color="primary"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mb-4" no-gutters>
                    <v-col
                        cols="3"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex align-center"
                    >
                        <span>Tags</span>
                    </v-col>
                    <v-col
                        cols="9"
                        xl="10"
                        lg="10"
                        md="10"
                        sm="10"
                        class="d-flex"
                    >
                        <v-btn
                            color="grey lighten-3"
                            elevation="0"
                            x-small
                            class="ma-1 text-capitalize grey--text text--darken-1  "
                            v-for="(tag, i) in quoteData.tags"
                            :key="i"
                        >
                            <span>{{ tag }}</span>
                            <v-icon
                                v-if="
                                    quoteData &&
                                        (quoteData.status === 'OPEN' ||
                                            quoteData.status === 'APPROVAL')
                                "
                                @click="deleteQuoteTag(tag)"
                                class="ml-1"
                                small
                            >
                                mdi-close-circle
                            </v-icon>
                        </v-btn>
                        <v-btn
                            @click="openTagsForm"
                            depressed
                            fab
                            x-small
                            color="primary"
                            class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                            v-if="
                                quoteData &&
                                    (quoteData.status === 'OPEN' ||
                                        quoteData.status === 'APPROVAL') &&
                                    !history
                            "
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                    <!-- TAGS FORM -->
                    <v-dialog
                        :retain-focus="false"
                        v-model="tagsDialog"
                        persistent
                        max-width="400px"
                    >
                        <TagsForm
                            v-if="tagsDialog"
                            :setting="selectedSettings"
                            :quoteTags="quoteData.tags"
                            @closeTagsDialog="closeTagsDialog"
                            @saveTags="saveTags"
                        />
                    </v-dialog>
                </v-row>
                <v-row v-if="quoteData" class="mb-4" no-gutters>
                    <v-col
                        cols="3"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex align-center"
                    >
                        <span>Country</span>
                    </v-col>
                    <v-col
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                        sm="12"
                        class="justify-left"
                    >
                        <v-autocomplete
                            :disabled="
                                (quoteData &&
                                    quoteData.status !== 'OPEN' &&
                                    quoteData.status !== 'APPROVAL') ||
                                    history
                            "
                            v-model="quoteData.country"
                            :items="this.countries"
                            prepend-icon="mdi-earth"
                            label="Country"
                            @change="selectState"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row v-if="quoteData" class="mb-4" no-gutters>
                    <v-col
                        cols="3"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex align-center"
                    >
                        <span>State</span>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="12">
                        <v-autocomplete
                            :disabled="
                                (quoteData &&
                                    quoteData.status !== 'OPEN' &&
                                    quoteData.status !== 'APPROVAL') ||
                                    history
                            "
                            v-model="quoteData.state"
                            @change="selectCity"
                            :items="this.states"
                            prepend-icon="mdi-map-marker-radius"
                            label="State"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row v-if="quoteData" class="mb-4" no-gutters>
                    <v-col
                        cols="3"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex align-center"
                    >
                        <span>City</span>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="12">
                        <v-combobox
                            :disabled="
                                (quoteData &&
                                    quoteData.status !== 'OPEN' &&
                                    quoteData.status !== 'APPROVAL') ||
                                    history
                            "
                            v-model="quoteData.city"
                            :filter="filter"
                            :hide-no-data="!searchLocation"
                            :items="this.cities"
                            hide-details
                            prepend-icon="mdi-city"
                            :search-input.sync="searchLocation"
                            hide-selected
                            label="City"
                            required
                            flat
                        >
                            <template v-slot:item="{ index, item }">
                                <v-text-field
                                    v-if="editing === item"
                                    v-model="editing.text"
                                    autofocus
                                    flat
                                    background-color="transparent"
                                    hide-details
                                    solo
                                    @keyup.enter="edit(index, item)"
                                ></v-text-field>
                                <span v-else>
                                    {{ item.text }}
                                </span>
                                <v-spacer></v-spacer>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" class="pr-0">
                <v-row no-gutters>
                    <v-col cols="12" xl="10" lg="10" md="10" sm="10">
                        <ClientInformationQuote
                            v-if="quoteData && readClients"
                            :quote="quoteData"
                            :settings="settings"
                            :quoteStatus="quoteData.status"
                            :historyQuote="history"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="d-flex justify-start justify-lg-end justify-md-end justify-xl-end justify-sm-end"
                        v-if="!$vuetify.breakpoint.smAndDown"
                    >
                        <span class="mr-3">Status:</span>
                        <v-btn
                            v-if="quoteData"
                            :style="{ width: '100px' }"
                            rounded
                            small
                            elevation="0"
                            :color="getColor(quoteData.status)"
                            class="text-center btnStatus white--text mr-4"
                        >
                            {{ showStatus(quoteData.status) }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mt-5 mb-2" no-gutters>
                    <v-col cols="6" sm="6" md="3" lg="3" xl="3">
                        <span class="font-weight-bold">Last updated</span>
                        <br />
                        <span v-if="quoteData && quoteData.lastUpdate">{{
                            quoteData.lastUpdate
                                ? formatDate(quoteData.lastUpdate._seconds)
                                : formatDate(quoteData.createdOn._seconds)
                        }}</span>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" lg="3" xl="3">
                        <span class="font-weight-bold">Invitation Date</span>
                        <br />
                        <span v-if="quoteData">{{
                            quoteData.invitationDate
                                ? formatDate(
                                      quoteData.invitationDate._seconds + 60
                                  )
                                : ''
                        }}</span>
                        <v-btn
                            v-if="
                                quoteData &&
                                    quoteData.archive == false &&
                                    !history
                            "
                            @click="
                                openSetPromiseForm(
                                    'SET INVITATION DATE',
                                    quoteData.invitationDate
                                )
                            "
                            class="ml-6"
                            depressed
                            fab
                            x-small
                            color="primary"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" lg="3" xl="3">
                        <span class="font-weight-bold">Due Date</span>
                        <br />
                        <span v-if="quoteData">{{
                            quoteData.requestDate
                                ? formatDate(
                                      quoteData.requestDate._seconds + 60
                                  )
                                : ''
                        }}</span>
                        <v-btn
                            v-if="
                                quoteData &&
                                    quoteData.archive == false &&
                                    !history
                            "
                            @click="
                                openSetPromiseForm(
                                    'SET DUE DATE',
                                    quoteData.requestDate
                                )
                            "
                            class="ml-6"
                            depressed
                            fab
                            x-small
                            color="primary"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" lg="3" xl="3">
                        <span class="font-weight-bold">Promise Date</span>
                        <br />
                        <span v-if="quoteData">{{
                            quoteData.promiseDate
                                ? formatDate(
                                      quoteData.promiseDate._seconds + 60
                                  )
                                : ''
                        }}</span>
                        <v-btn
                            v-if="
                                quoteData &&
                                    quoteData.archive == false &&
                                    !history
                            "
                            @click="
                                openSetPromiseForm(
                                    'SET PROMISE DATE',
                                    quoteData.promiseDate
                                )
                            "
                            class="ml-6"
                            depressed
                            fab
                            x-small
                            color="primary"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="quoteData" class="mr-3" no-gutters>
                    <v-col cols="12">
                        <v-textarea
                            name="input-7-1"
                            v-model="quoteData.notes"
                            hide-details
                            label="Notes"
                            rows="3"
                            :disabled="quoteData.archive == true"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- Set Quote Dates -->
        <v-dialog
            v-model="setQuoteDates"
            max-width="600"
            persistent
            :retain-focus="false"
        >
            <SetQuoteDates
                v-if="setQuoteDates"
                :title="title"
                :date="
                    date && date._seconds ? formatDate2(date._seconds) : null
                "
                :minDate="minDate"
                :loading="selectedLoading"
                :quoteId="id"
                :quote="quoteData"
                @updateDates="updateDates"
                @closeQuoteDatesForm="closeQuoteDatesForm"
            />
        </v-dialog>
    </div>
</template>

<script>
import API from '@/services/api'
import { storage } from '@/services/firebase'
import { mapState, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import DocumentForm from '@/components/Settings/DocumentForm'
import ClientInformationQuote from '@/components/Quotes/ClientInformationQuote'
const countrycitystate = require('countrycitystatejson')
import { loadImage } from '@/helpers/imageHandler'

export default {
    name: 'QuoteGeneralInfo',
    props: {
        id: String,
        quote: Object,
        settings: Array,
        costVersions: Object,
        costs: Array,
        history: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        DocumentForm,
        ChangeMainContact: () =>
            import('@/components/Quotes/ChangeMainContact'),
        ChangeEstimator: () => import('@/components/Quotes/ChangeEstimator'),
        ChangePreconManager: () =>
            import('@/components/Quotes/ChangePreconManager'),
        ClientInformationQuote,
        TagsForm: () => import('@/components/Settings/TagsForm'),
        SetQuoteDates: () => import('@/components/Quotes/SetQuoteDates'),
    },
    data() {
        return {
            chat: undefined,
            menu: false,
            companyId: JSON.parse(localStorage.getItem('company')),
            user: JSON.parse(localStorage.getItem('userId')),
            folderDocuments: 'quotes_documents',
            loading: false,
            files: [],
            addDialog: false,
            addDialogContact: false,
            indexDoc: null,
            indexAttached: null,
            indexDocs: null,
            title: '',
            deleteDialog: false,
            search: '',
            dialogDocument: false,
            docQuote: null,
            folderUsersPictures: 'users_pictures',
            collaborators: [],
            document: {},
            loadingError: false,
            errorMsg: null,
            error: false,
            rules: {
                required: v => !!v || 'Required',
                number: v => (v <= 100 && v >= 0) || 'Error',
            },
            listCountries: null,
            countries: [],
            cities: [],
            states: [],
            shortNameCity: null,
            editing: null,
            searchLocation: null,
            oldQuote: Object.assign({}, this.quote),
            changeMainContact: false,
            tagsDialog: false,
            selectedSettings: undefined,
            dataCollaborators: [],
            readClients: false,
            users: [],
            setQuoteDates: false,
            minDate: '',
            date: '',
            selectedLoading: false,
            changeEstimator: false,
            changePreconManager: false,
            quoteData: {},
        }
    },
    inject: {
        replaceQuote: {
            from: 'replaceQuote',
            default: () => null,
        },
        replaceQuoteLocal: {
            from: 'replaceQuoteLocal',
            default: () => null,
        },
    },
    computed: {
        ...mapState(['saveBtn', 'costQuotes', 'quotes', 'historyCosts']),
        filterDataColab() {
            return this.dataCollaborators.filter(c => {
                return c === undefined
                    ? false
                    : c.id != this.quoteData.mainContact.id &&
                          c.id != this.quoteData.preconManagerId &&
                          c.id != this.quoteData.estimatorId
            })
        },
        validateCollaborator() {
            return !this.quoteData.collaborators.includes(this.user)
        },
    },
    watch: {
        settings: function() {
            this.setSettings()
        },

        quote: async function() {
            this.updateSeeMenuAction(false)
            await this.getDataContacts()
            this.updateSeeMenuAction(true)
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        showStatus(status) {
            if (status && status.toLowerCase() == 'open') {
                return 'bidding'
            }
            if (status && status.toLowerCase() == 'sent') {
                return 'follow up'
            }
            return status
        },
        async saveTags(tags) {
            try {
                this.error = false
                this.errorMsg = ''
                await API.updateQuote(this.quoteData.id, {
                    notificationId: 'LrubMkCSfpNwEoXemQKL',
                    tags,
                })
                this.quoteData.tags = [...tags]
                this.replaceQuoteLocal(this.quoteData.id, this.quoteData)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openSetPromiseForm(title, date = null) {
            this.title = title
            this.date = date
            this.minDate = new Date().toISOString()
            if (this.title != 'SET PROMISE DATE') {
                this.minDate = ''
            }
            this.setQuoteDates = true
        },
        updateDates(params) {
            const title = params.title
            const date = params.date
            if (title == 'SET INVITATION DATE') {
                this.quoteData.invitationDate = date.invitationDate
            }
            if (title == 'SET DUE DATE') {
                this.quoteData.requestDate = date.requestDate
            }
            if (title == 'SET PROMISE DATE') {
                this.quoteData.promiseDate = date.promiseDate
            }
        },
        closeQuoteDatesForm() {
            this.title = ''
            this.date = ''
            this.minDate = ''
            this.setQuoteDates = false
        },
        closeTagsDialog() {
            this.tagsDialog = false
        },
        openTagsForm() {
            this.selectedSettings = this.settings.find(
                setting => setting.name === 'Company'
            )
            if (!this.quoteData.tags) {
                this.quoteData.tags = []
            }
            this.tagsDialog = true
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('MMM/DD/YYYY')
        },
        formatDate2(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD')
        },
        async deleteQuoteTag(tag) {
            try {
                this.loading = true
                this.errorMsg = ''
                const tags = this.quoteData.tags.filter(t => t !== tag)
                await API.updateQuote(this.quoteData.id, {
                    notificationId: 'LrubMkCSfpNwEoXemQKL',
                    tags,
                })
                this.quoteData.tags = [...tags]
                this.replaceQuoteLocal(this.quoteData.id, this.quoteData)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openChangeEstimator() {
            if (!this.history) {
                this.changeEstimator = true
            }
        },
        replaceEstimator(estimatorInfo) {
            this.quoteData.estimatorInfo = estimatorInfo.user
            this.quoteData.estimatorId = estimatorInfo.estimatorId
            this.quoteData.collaborators = estimatorInfo.collaborators
            this.replaceQuoteAction({
                id: this.quoteData.id,
                quote: this.quoteData,
            })
            this.replaceQuoteLocal(this.quoteData.id, this.quoteData)
        },
        closeChangeEstimator() {
            this.changeEstimator = false
        },
        openChangeMainContact() {
            if (!this.history) {
                this.changeMainContact = true
            }
        },
        replaceMainContact(mainContact) {
            this.quoteData.mainContact = mainContact
            this.quoteData.userId = mainContact.id
            this.replaceQuoteAction({
                id: this.quoteData.id,
                quote: this.quoteData,
            })
            this.replaceQuoteLocal(this.quoteData.id, this.quoteData)
        },
        closeChangeMainContact() {
            this.changeMainContact = false
        },
        openChangePreconManager(quote) {
            if (quote.status == 'rejected') {
                return null
            }
            this.changePreconManager = true
        },
        replacePreconManager(params) {
            this.quoteData.collaborators = params.collaborators
            this.quoteData.preconManagerInfo = params.user
            this.quoteData.preconManagerId = params.user.id
            this.getDataContacts()
            this.replaceQuoteAction({
                id: this.quoteData.id,
                quote: this.quoteData,
            })
            this.replaceQuoteLocal(this.quoteData.id, this.quoteData)
        },
        closeChangePreconManager() {
            this.changePreconManager = false
        },
        getColor(status) {
            if (status == 'OPEN') return 'primary'
            else if (status == 'AWARDED') return 'success'
            else if (status == 'LOST') return 'error'
            else if (status == 'SENT') return 'warning'
            else return 'orange'
        },
        deleteSearch() {
            this.search = ''
        },

        ...mapActions([
            'quoteChangesAction',
            'addUsersAction',
            'replaceQuoteAction',
            'updateQuoteAction',
            'addCostAction',
            'addHistoryCostAction',
            'addQuoteAction',
            'updateSeeMenuAction',
        ]),

        openDialogCollaborator() {
            if (!this.quoteData.collaborators) {
                Object.defineProperty(this.quoteData, 'collaborators', {
                    writable: true,
                })
            }
            if (!this.quoteData.dataCollaborators) {
                Object.defineProperty(this.quoteData, 'dataCollaborators', {
                    value: [],
                    writable: true,
                })
            }
            this.addDialog = true
        },
        closeDialog() {
            //this.$refs.collaborator.reset()
            this.addDialog = false
        },
        openQuoteDocument() {
            this.dialogDocument = true
            this.title = 'Documents Quote'
        },
        openAttachDocument() {
            this.dialogDocument = true
            this.title = 'Attach Document'
        },

        deleteDocQuote(file) {
            this.docToDelete = file
            this.indexDoc = this.quoteData.files.findIndex(
                x => x.file == this.docToDelete
            )
            this.indexAttached = this.quoteData.attached.findIndex(
                x => x.file == this.docToDelete
            )
            this.indexDocs = this.quoteData.docs.findIndex(
                x => x.file == this.docToDelete
            )
            this.deleteDialog = true
        },
        objectsEqual(o1, o2) {
            typeof o1 === 'object' && Object.keys(o1).length > 0
                ? Object.keys(o1).length === Object.keys(o2).length &&
                  Object.keys(o1).every(p => this.objectsEqual(o1[p], o2[p]))
                : o1 === o2
        },
        updateQuote: async function() {
            try {
                this.loading = true
                const { id } = this.quoteData
                if (!this.quoteData.collaborators) {
                    this.quoteData.collaborators = []
                    this.quoteData.collaborators.splice(
                        0,
                        0,
                        this.quoteData.userId
                    )
                } else if (
                    !this.quoteData.collaborators.includes(
                        this.quoteData.userId
                    )
                ) {
                    this.quoteData.collaborators.splice(
                        0,
                        0,
                        this.quoteData.userId
                    )
                }
                let changes = {}
                Object.keys(this.quoteData).forEach(key => {
                    if (
                        key !== 'clients' &&
                        key !== 'client' &&
                        ((this.oldQuote[key] === undefined &&
                            this.quoteData[key] !== undefined) ||
                            (this.quoteData[key] !== undefined &&
                                JSON.stringify(this.quoteData[key]) !==
                                    JSON.stringify(this.oldQuote[key])) ||
                            (this.quoteData[key] &&
                                this.quoteData[key].value &&
                                Array.isArray(this.quoteData[key]) &&
                                (this.quoteData[key] && this.oldQuote[key]
                                    ? this.objectsEqual(
                                          this.quoteData[key],
                                          this.oldQuote[key]
                                      )
                                    : this.quoteData[key] &&
                                      this.oldQuote[key])))
                    ) {
                        changes[key] =
                            key === 'city'
                                ? this.quoteData[key].text
                                : this.quoteData[key]
                    }
                })
                const {
                    data: { quote },
                } = await API.updateQuote(id, {
                    notificationId: 'LrubMkCSfpNwEoXemQKL',
                    ...changes,
                })
                this.oldQuote = Object.assign({}, this.quoteData)
                quote.createdOn = moment
                    .unix(quote.createdOn._seconds)
                    .format('MMM/DD/YYYY')
                quote.updatedOn = moment
                    .unix(quote.updatedOn._seconds)
                    .format('MMM/DD/YYYY')
                quote.users = this.quoteData.users
                quote.clients = this.quoteData.clients
                quote.client = this.quoteData.client
                quote.mainContact = this.quoteData.mainContact
                this.replaceQuoteAction({ id, quote })
                this.replaceQuoteLocal(this.quoteData.id, {
                    ...this.quoteData,
                    ...quote,
                })
                this.quoteChangesAction(true)
                if (this.indexAttached >= 0) {
                    this.quoteData.attached.splice(this.indexAttached, 1)
                } else if (this.indexDocs >= 0) {
                    this.quoteData.docs.splice(this.indexDocs, 1)
                }
                this.replaceQuoteLocal(this.quoteData.id, this.quoteData)
            } catch (error) {
                console.log(error)
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
        deleteDoc: async function() {
            this.quoteData.files.splice(this.indexDoc, 1)
            await this.updateQuote()
        },

        closeDialogDocument() {
            this.dialogDocument = false
        },

        addFile(file) {
            if (file.attach) {
                this.quoteData.attached.push(file)
            } else {
                this.quoteData.docs.push(file)
            }
            this.replaceQuoteLocal(this.quoteData.id, this.quoteData)
        },

        addCollaborator: async function() {
            try {
                this.loading = true
                this.quoteData.dataCollaborators = []
                for (const collaboratorId of this.quoteData.collaborators) {
                    let dataUser
                    if (this.quoteData.users.length > 0) {
                        dataUser = this.quoteData.users.find(
                            user => collaboratorId == user.id
                        )
                    } else {
                        dataUser = this.users.find(
                            user => collaboratorId == user.id
                        )
                    }
                    if (
                        dataUser &&
                        !dataUser.profilePicURL &&
                        dataUser.picture
                    ) {
                        dataUser.profilePicURL = await loadImage(
                            `${this.companyId}/${this.folderUsersPictures}`,
                            dataUser.picture
                        )
                    }
                    if (dataUser) {
                        this.quoteData.dataCollaborators.push(dataUser)
                    }
                }
                // set data collaborators
                this.dataCollaborators = [...this.quoteData.dataCollaborators]
                await this.updateQuote()
                this.addDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        selectStatus(event) {
            this.quoteData.status = event
        },

        openFile: async function(file) {
            const id = this.quoteData.id
            const path = `${this.companyId}/${this.folderDocuments}/${id}`
            const storageRef = storage().ref(path)
            const documentRef = storageRef.child(file)
            await documentRef
                .getDownloadURL()
                .then(url => {
                    this.document.url = url
                })
                .catch(() => {
                    this.document.url = ''
                })
            //window.location.assign(this.document.url)
            setTimeout(() => {
                window.open(this.document.url, '_blank')
            })
        },
        setSettings() {
            const indexExchanges = this.settings.findIndex(
                u => u.name === 'Exchange'
            )
            this.settingExchange = this.settings[indexExchanges]
        },
        getUsers: async function() {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                let usersIdsBySearch = [...(this.quoteData.collaborators || [])]
                const {
                    data: { users },
                } = await API.getLiteUsers()
                for (const userId of usersIdsBySearch) {
                    const index = users.findIndex(u => u.id == userId)
                    if (index > -1) {
                        if (users[index].picture) {
                            users[index].profilePicURL = await loadImage(
                                `${this.companyId}/${this.folderUsersPictures}`,
                                users[index].picture
                            )
                        }
                        users[index].createdOn = moment
                            .unix(users[index].createdOn.seconds)
                            .format('MMM/DD/YYYY HH:mm')
                    }
                }
                this.users = users
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getDataContacts() {
            if (this.users.length <= 0) {
                await this.getUsers()
            }
            this.quoteData.users = _.cloneDeep(this.users)
            this.quoteData.attached = this.quoteData.files.filter(
                x => x.attach == true
            )
            this.quoteData.docs = this.quoteData.files.filter(
                x => x.attach == false
            )
            if (this.quoteData.collaborators) {
                this.quoteData.dataCollaborators = []
                this.quoteData.collaborators.forEach(element => {
                    const dataUser = this.users.find(user => element == user.id)
                    if (dataUser)
                        this.quoteData.dataCollaborators.push(dataUser)
                })
                this.dataCollaborators = [...this.quoteData.dataCollaborators]
            }
            if (this.quoteData.followUpCollaborators) {
                this.quoteData.followUpDataCollaborators = []
                this.quoteData.followUpCollaborators.forEach(element => {
                    const dataUser = this.users.find(user => element == user.id)
                    if (dataUser)
                        this.quoteData.followUpDataCollaborators.push(dataUser)
                })
            }
            if (this.quoteData.userId) {
                this.quoteData.mainContact = this.users.find(
                    user => this.quoteData.userId == user.id
                )
            }
            if (this.quoteData.estimatorId && !this.quoteData.estimatorInfo) {
                this.quoteData.estimatorInfo = this.users.find(
                    user => this.quoteData.estimatorId == user.id
                )
            }
            if (
                this.quoteData.preconManagerId &&
                !this.quoteData.preconManagerInfo
            ) {
                this.quoteData.preconManagerInfo = this.users.find(
                    user => this.quoteData.preconManagerId == user.id
                )
            }
            this.collaborators = _.cloneDeep(this.quoteData.users)
            const indexMainContact = this.collaborators.findIndex(
                user => user.id == this.id
            )
            if (indexMainContact !== -1) {
                this.collaborators.splice(indexMainContact, 1)
            }
        },
        getHistoryCosts: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const {
                    data: { historyCosts },
                } = await API.getHistoryCosts(this.id)
                if (historyCosts.length > 0) {
                    historyCosts.forEach(cost => {
                        cost.createdOn = moment
                            .unix(cost.createdOn._seconds)
                            .format('MMM/DD/YYYY h:mm a')
                    })

                    this.addHistoryCostAction({
                        idQuote: this.id,
                        historyCosts: historyCosts,
                    })
                    const versions = this.historyCosts.find(
                        x => x.idQuote == this.id
                    )
                    this.costVersions = versions
                } else {
                    this.costVersions = {}
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        selectState: function(event) {
            const index = this.listCountries.findIndex(x => x.name === event)
            this.shortNameCity = this.listCountries[index].shortName
            this.states = countrycitystate.getStatesByShort(this.shortNameCity)
        },
        selectCity: function(event) {
            this.cities = []
            this.cities[0] = { header: 'Select or create one' }
            let data = countrycitystate.getCities(this.shortNameCity, event)
            data.forEach(city => {
                this.cities.push({ text: city })
            })
        },
        filter(item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => (val != null ? val : '')
            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },
    },

    async mounted() {
        try {
            this.quoteData = _.cloneDeep(this.quote)
            this.updateSeeMenuAction(false)
            this.listCountries = countrycitystate.getCountries()
            this.countries = this.listCountries.map(x => x.name)
            this.loading = true
            this.loadingError = false
            this.error = false
            this.errorMsg = null
            if (this.users.length == 0) {
                await this.getUsers()
                if (this.quoteData) {
                    await this.getDataContacts()
                }
            } else {
                this.quoteData.users = this.users
                if (this.quoteData) {
                    await this.getDataContacts()
                }
            }

            if (this.settings) {
                await this.setSettings()
            }

            // load states
            if (this.quoteData && this.quoteData.country) {
                this.selectState(this.quoteData.country)
                // load cities
                if (this.quoteData.state) {
                    this.selectCity(this.quoteData.state)
                }
            }
            if (
                this.quoteData.dataCollaborators &&
                this.quoteData.dataCollaborators.length > 0
            ) {
                this.dataCollaborators = [...this.quoteData.dataCollaborators]
            }
            const {
                data: { user },
            } = await API.getMyInfo()
            const readClients = user.permissions.find(x => x == 'readClients')
            if (readClients) {
                this.readClients = true
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
}
</script>

<style scoped>
.text-singleline {
    line-height: 0.75rem;
}
.btnStatus {
    cursor: default;
}
.content {
    overflow-x: auto;
}
.button {
    background: gray;
}
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 0px;
}
</style>
