<template>
    <v-card :loading="loading">
        <v-card-title>
            <span class="text-h5">Select Scope</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-autocomplete
                        flat
                        solo
                        hide-details
                        outlined
                        v-model="importedQuote"
                        :items="quotes"
                        :item-text="formatQuote"
                        item-value="id"
                        label="Select a Quote"
                        :custom-filter="filterQuotes"
                    />
                </v-row>
                <v-row class="mt-4">
                    <v-col cols="12" class="px-0">
                        <v-data-table
                            :loading="loading"
                            :headers="headers"
                            class="elevation-0"
                            :items="scopes"
                            :mobile-breakpoint="0"
                            hide-default-footer
                            disable-pagination
                            :height="$vuetify.breakpoint.lg ? '30vh' : '30vh'"
                            fixed-header
                        >
                            <!--HEADERS-->
                            <template v-slot:[`header.selectAll`]="{ header }">
                                <div class="d-flex">
                                    <h3 class="my-0 mx-2">{{ header.text }}</h3>
                                    <v-simple-checkbox
                                        color="primary"
                                        class="mt-n1"
                                        v-model="checkAll"
                                        @click="selectAllScopes"
                                    />
                                </div>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.salePrice`]="{ item }">
                                <p class="my-0">{{ formatNumber(item) }}</p>
                            </template>
                            <template v-slot:[`item.selectAll`]="{ item }">
                                <v-simple-checkbox
                                    color="primary"
                                    class="mt-n1"
                                    v-model="item.check"
                                />
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-btn color="red" text @click="close">
                Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                rounded
                depressed
                dark
                :loading="loading"
                @click="importScopes"
            >
                Import
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import API from '@/services/api'

export default {
    name: 'ImportScopes',
    props: {
        quote: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        checkAll: false,
        loading: true,
        importedQuote: {},
        quotes: [],
        scopes: [],
        headers: [
            {
                text: 'DESCRIPTION',
                value: 'reference',
                class: 'grey lighten-3',
                sortable: false,
                align: 'left',
            },
            {
                text: 'CATEGORY',
                value: 'category',
                class: 'grey lighten-3',
                sortable: false,
                align: 'center',
            },
            {
                text: 'QTY',
                value: 'quantity',
                class: 'grey lighten-3',
                sortable: false,
                align: 'center',
            },
            {
                text: 'SALE PRICE',
                value: 'salePrice',
                class: 'grey lighten-3',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Select all',
                value: 'selectAll',
                class: 'grey lighten-3',
                sortable: false,
                align: 'center',
                width: '150',
            },
        ],
        scopesCopy: [],
    }),
    watch: {
        async importedQuote() {
            try {
                this.loading = true
                if (this.importedQuote) {
                    this.checkAll = false
                    this.selectAllScopes()
                    let scopes = await this.getScopes(this.importedQuote)
                    scopes = scopes.filter(scope => scope.type)
                    this.scopes = scopes.filter(cost => cost.type == 'cost')
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    computed: {
        ...mapState(['settings']),
    },
    async mounted() {
        try {
            this.loading = true
            this.scopesCopy = await API.getScopes()
            this.quotes = await this.getQuotes()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        selectAllScopes() {
            this.scopes.forEach((scope, index) => {
                this.$set(this.scopes, index, {
                    ...scope,
                    check: this.checkAll,
                })
            })
        },
        formatNumber(item) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'COP',
                maximumFractionDigits: 2,
            }).format(item.salePrice)
        },
        async importScopes() {
            try {
                this.loading = true
                const scopes = await API.exportScopes({
                    destinationQuote: this.quote.id,
                    scopes: this.scopes
                        .filter(scope => scope.check)
                        .map(scope => scope.id),
                })
                await this.$emit('importScopes', scopes)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        formatQuote(item) {
            if (item.number) {
                return `(${item.number}) ${item.name}`
            } else {
                return `${item.name}`
            }
        },
        filterQuotes(item, queryText) {
            const searchText = queryText.toLowerCase()
            const number = item.number ?? ''
            const name = item.name
            return (
                number.toLowerCase().includes(searchText) ||
                name.toLowerCase().includes(searchText)
            )
        },
        async getQuotes() {
            const quotes = this.scopesCopy
                .map(item => item.quoteData)
                .filter(qd => qd && Object.keys(qd).length > 0)
            quotes.push({ name: 'Default Project', id: '000000' })
            const uniqueList = Array.from(
                new Map(quotes.map(item => [item.id, item])).values()
            )
            return uniqueList
        },
        async getScopes(id) {
            if (id === '000000') {
                return this.scopesCopy.filter(item => !('quoteData' in item))
            } else {
                return this.scopesCopy.filter(
                    qd => qd.quoteData && qd.quoteData.id == id
                )
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style scoped>
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.no-border {
    border: none !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
tr:hover {
    background: unset !important;
}
th,
td {
    font-size: 0.875rem !important;
}
</style>
